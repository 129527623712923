app.a2z = (function($){
  'use strict';

  function init(){
    var form = $('.a2z');

    $('input[type=checkbox]', form).each(function() {
      var labelID = this.id;
      if (!this.checked) {
        $("label[for='" + labelID + "']").addClass('unchecked');
      }
    });
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
