/**
  Returns a function, that, as long as it continues to be invoked, will not
  be triggered. The function will be called after it stops being called for
  N milliseconds. If `immediate` is passed, trigger the function on the
  leading edge, instead of the trailing.
  http://davidwalsh.name/javascript-debounce-function
*/
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;

    // This doesn't work on iOS, so let's bipass it on touch in general
    if ( Modernizr.touch ) {
      func.apply(context, args);
    }

    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
  Return true if an element will be in view soon.
  Provide a second argument with how many pixels "soon"
  should mean, or leave the default as 750.
*/
function almostVisible(element, threshold) {

  threshold = typeof(threshold) === 'undefined' ? 1500 : threshold;

  var w = $(window),
      magicNumber = Math.round(element.offset().top - threshold);

  // If our element will be in view soon
  return ( w.scrollTop() + w.height() >= magicNumber );

}
