app.proofPointFeature = (function($, breakpoints){
  'use strict';

  var proofPointFeature = {

    elements: '.proof-point-feature',

    init: function() {

      // Cache items
      proofPointFeature.elements = $(proofPointFeature.elements);

      Harvey.attach('screen and (max-width: ' + breakpoints.mid + 'px)', {
        on: function() {
          proofPointFeature.move();
        },
        off: function() {
          proofPointFeature.putBack();
        }
      });

    }, // init

    move: function() {

      proofPointFeature.elements.each(function(){

        // Them them elements
        var container = $(this),
            media = $(this).find('.proof-point-feature__media');

        // If the last one, set data
        if ( media.next().length < 1 ) {
          media.data('rlAtEnd', true);
        }

        media.prependTo(container);

      });

    }, // move

    putBack: function() {

      proofPointFeature.elements.each(function(){

        // Them them elements
        var container = $(this),
            media = $(this).find('.proof-point-feature__media');

        // If the item was last, append
        if ( media.data('rlAtEnd') ) {
          media.appendTo(container);
        }


      });

    }

  };

  $(document).on('ready', proofPointFeature.init);

  return proofPointFeature;

})(jQuery, app.breakpoints);
