app.tooltip = (function($){
  'use strict';

    function init(){
      const $tooltip = $('.js-tooltip button');

      $tooltip.on('click', function() {
        const $this = $(this);
        const $content = $this.next();

        if( $content.attr('aria-hidden') == 'true') {
          $content.attr('aria-hidden', 'false')
        } else {
          $content.attr('aria-hidden', 'true')
        }
      });
    }
    $(document).on('ready', init);

})(jQuery);
