app.sectionNav = (function($){
  'use strict';

  function init(){
    var toggle = $('.js-section-nav-toggle');

    countChildren();

    toggle.click(function(event) {
      event.preventDefault();

      var btnText = $(this).html(),
        expandedLabel = $(this).data('rl-expanded');

      $(this).closest('.section-nav').toggleClass('is-expanded');

      $(this).html(expandedLabel);
      $(this).data('rl-expanded', btnText);
    });
  }

  function countChildren() {
    var parentNav = $('.section-nav__items');
    parentNav.each(function() {
      var childCount = $(this).children('li').length;
      if ( childCount > 4 ) {
        parentNav.closest('.section-nav').addClass('is-collapsable');
      }
    });

    $('.section-nav--subnav').addClass('is-collapsable');
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
