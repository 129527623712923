/*
* This Module provides tabs functionality
*/
app.tabs = (function ($) {
  'use strict';

  var tabs = {

    tabsWrapperHtml: '<div class="l-container"><div class="feature-tabs "><div class="feature-tabs__inner"><nav class="feature-tabs__items"></div></div></div></div>',
    tabClass: 'feature-tabs__item',
    isActiveClass: 'is-active',

    tabList: [],
    tabLookup: {},

    init: function () {
      $('.tab-context').each(tabs.foundTab);

      if (tabs.tabList.length === 0) {
        return;
      }

      var featureTabs = $(tabs.tabsWrapperHtml),
        nav = featureTabs.find('nav');

      for (var x = 0; x < tabs.tabList.length; x++) {
        nav.append(
          $(document.createElement('a'))
            .attr('href', '#')
            .addClass(tabs.tabClass)
            .addClass(x === 0 ? tabs.isActiveClass : '')
            .addClass('tab-' + tabs.tabList[x].id)
            .text(tabs.tabList[x].name)
            .data('tabId', tabs.tabList[x].id)
            .click(tabs.onClick)
        );
      }

      $('.l-top-content > .l-container').prepend(featureTabs);

      tabs.updateTabSelection(tabs.tabList[0].id);
    },

    foundTab: function () {
      tabs.initContext($(this).parent());
    },

    initContext: function (context) {
      if (context.data('tab-initialized')) {
        return;
      }

      context.data('tab-initialized', true);

      var currentTab = null;
      context.children().each(function (i) {
        var self = $(this);

        if (self.is('.tab-context')) {
          var tab = {
            id: self.data('rlTabId'),
            name: self.data('rlTabTitle')
          };
          if (!tabs.tabLookup[tab.id])
          {
            tabs.tabList.push(tab);
            tabs.tabLookup[tab.id] = tab;
          }
          currentTab = tab;
        } else if (currentTab) {
          self.addClass('tab-content');
          self.addClass('tab-content-' + currentTab.id);
        }
      });
    },

    onClick: function (e) {
      e.preventDefault();

      tabs.updateTabSelection($(this).data('tabId'));
    },

    updateTabSelection: function (id) {
      $('.' + tabs.tabClass).removeClass(tabs.isActiveClass);
      $('.tab-' + id).addClass(tabs.isActiveClass);

      $('.tab-content').hide();
      $('.tab-content-' + id).show();
    }
  };

  tabs.init();
})(jQuery);
