app.columns = (function($, breakpoints){
  'use strict';

  var columns = {

    init: function() {

      // Equal heights
      columns.equalHeight.init();

    },

    equalHeight: {

      els: '.columns--equal-height',

      init: function() {

        if ( !Modernizr.flexbox ) {

          // Cache items
          columns.equalHeight.els = $(columns.equalHeight.els);

          // If there's no flexbox use equalHeightColumns instead
          $(columns.equalHeight.els).equalHeightColumns({
            selector: '.columns__item',
            outerHeight: true
          });

        }

      }

    }

  };

  $(document).on('ready', columns.init);

  return columns;

})(jQuery, app.breakpoints);
