app.hero = (function($, Harvey, breakpoints) {
  'use strict';

  function init() {
    $('.hero').each(createHero);
  }

  function createHero(index, hero) {
    var $hero = $(hero);
    var $heroItemsWrap = $hero.find('.hero__items');
    var $heroItems = $heroItemsWrap.find('.hero__item');
    var thumbnails = [];

    //Initialize media
    $heroItems.each(function() {
      var videoSources = [];


      //Adding image
      if ($(this).data('rl-image')) {
        $(this).css('background-image', 'url(' + $(this).data('rl-image') + ')');
      }

      //Adding video
      if ($(this).data('rl-mp4')) {
        videoSources.push($('<source src="' + $(this).data('rl-mp4') + '" type="video/mp4">'));
      }

      if ($(this).data('rl-webm')) {
        videoSources.push($('<source src="' + $(this).data('rl-webm') + '" type="video/webm">'));
      }

      if ($(this).data('rl-ogg')) {
        videoSources.push($('<source src="' + $(this).data('rl-ogg') + '" type="video/ogg">'));
      }

      if (videoSources.length > 0) {
        var video = $('<video class="hero__item-video" loop autoplay muted>');
        video.prepend(videoSources);
        $(this).prepend(video);
        $(this).data('video', video[0]);
      }

      //Adding thumbnails
      if ($(this).data('rl-thumb')) {
        var thumbnail = $('<a href="#" class="hero__thumbnail" style="background-image: url(' + $(this).data('rl-thumb') + ')"></a>');
        thumbnail.on('click', function(e){
          e.preventDefault();
          var $hero = $(this).closest('.hero');
          var owl = $hero.find('.owl-carousel').data('owlCarousel');
          owl.goTo($(this).index());
        });
        thumbnails.push(thumbnail);
      }
    });

    if (thumbnails.length > 1) {
      var $heroNav = $('<div class="hero__nav">');
      $heroNav.prepend(thumbnails);
      $hero.prepend($heroNav);
      thumbnails[0].addClass('is-active');
    }

    if ($heroItems.length > 1) {
      $heroItemsWrap.addClass('owl-carousel');
      var owl = $heroItemsWrap.owlCarousel({
        singleItem: true,
        itemsScaleUp: true,
        transitionStyle: 'fade',
        autoPlay: 7000,
        slideChangeUpdate: function(data) {
        },
        afterInit: function($owl) {
          updateHero($owl.closest('.hero'));
        },
        afterUpdate: function($owl) {
          updateHero($owl.closest('.hero'));
        },
        afterMove: function($owl) {
          var $hero = $owl.closest('.hero');
          var owl = this;
          var $heroNav = $hero.find('.hero__nav');

          $('.hero__thumbnail.is-active', $heroNav).removeClass('is-active');
          $('.hero__thumbnail', $heroNav).eq(owl.currentItem).addClass('is-active');

          //playPauseVideo($owl);
        }
      });
    }

    bindResponsive($hero);

  }


  function updateHero($hero) {
    var $heroNav = $('.hero__nav', $hero);
    var $heroItemContent = $('.hero__item-content', $hero);
    var $aside = $('.hero__item-aside', $hero);

    $heroItemContent.css('margin-right', '');

    if ($(window).width() < breakpoints.medium) {
      return;
    }

    if ($heroNav.length) {
      var thumbnailsWidth = 0;
      $heroNav.children().each(function(){
        thumbnailsWidth += $(this).width();
      });
      $heroItemContent.css('margin-right', thumbnailsWidth + 100);
    }

    if ($aside.length) {
      $heroItemContent.css('margin-right', $aside.width() + 100);
    }

  }

  function bindResponsive($hero) {
    var $pageTitle = $('.page-title', $hero);
    var $form = $('.form', $hero);

    updateHero($hero);

    Harvey.attach('screen and (max-width: ' + breakpoints.medium + 'px)', {
      on: function() {

        $pageTitle.addClass('l-container').fold().insertAfter($hero);

        //The position of the form depends on existence of some elements. If there's a section-nav on the page, it goes under section-nav
        var $formTarget = ($('.section-nav').length) ? $('.section-nav') : $pageTitle;
        $formTarget = ($formTarget.length) ? $formTarget : $hero;

        $form.fold().insertAfter($formTarget);
        updateHero($hero);
      },
      off: function() {
        $pageTitle.removeClass('l-container').fold('restore');
        $form.fold('restore');
        updateHero($hero);
      }
    });
  }

  // function playPauseVideo($owl) {
  //   var owl = $owl.data('owlCarousel');
  // }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, Harvey, app.breakpoints);
