'use strict';

angular.module('redlands')
  .component('rlNewsItem', {
    bindings: {
      item: '=rlNewsItem'
    },
    template: ['<a ng-href="{{vm.item.url}}" class="content-grid__item-wrapper" ng-class="{\'content-grid__item-wrapper--tile\': !vm.item.image}">',
              '<div ng-if="vm.item.image" class="content-grid__item-image">',
              '<img ng-src="{{vm.item.image}}">',
              '</div>',
              '<div class="content-grid__item-body">',
                '<span class="content-grid__item-tag tag">{{vm.item.type}} / {{vm.item.categoryTitle}}</span>',
                '<h3>{{vm.item.title}}</h3>',
                '<div ng-if="!vm.item.image && vm.item.description">',
                '<div ng-bind-html="vm.item.description | trust"></div>',
                '<span class="more-link more-link--ghost more-link--no-icon">Read More</span></div>',
              '</div>',
              '<div ng-if="!vm.isEvent()" class="content-grid__item-footer">',
                '<p class="item-date">{{vm.item.date | date : "mediumDate"}}<span ng-if="vm.isMultiDate()"> – {{vm.item.endDate | date : "mediumDate"}}</span></p>',
              '</div>',
              '<div ng-if="vm.isEvent()" class="content-grid__item-footer">',
                '<div class="content-grid__item-footer-cell content-grid__item-date">',
                '<div class="content-grid__item-date-group">',
                '<span class="content-grid__item-month">{{vm.item.date | date : "MMM"}}</span> <span class="content-grid__item-day">{{vm.item.date | date : "d"}}</span>',
                '</div>',
                '<div ng-if="vm.isMultiDate()" class="content-grid__item-date-divider">–</div>',
                '<div ng-if="vm.isMultiDate()" class="content-grid__item-date-group">',
                '<span class="content-grid__item-month">{{vm.item.endDate | date : "MMM"}}</span> <span class="content-grid__item-day">{{vm.item.endDate | date : "d"}}</span>',
                '</div>',
                '</div>',
                '<div class="content-grid__item-footer-cell item-description">{{vm.item.date | date : "EEEE"}}<span ng-if="vm.isMultiDate()"> to {{vm.item.endDate | date : "EEEE"}}</span>, <span ng-if="!vm.isAllDay()">{{vm.item.date | date : "shortTime"}}</span> at {{vm.item.location}}</div>',
              '</div>',
            '</a>'

            ].join(''),
    controller: rlNewsItem,
    controllerAs: 'vm'
  });

rlNewsItem.$inject = ['$filter'];

function rlNewsItem($filter){
  /* jshint validthis: true */
  var vm = this;
  vm.isMultiDate = isMultiDate;
  vm.isEvent = isEvent;
  vm.isAllDay = isAllDay;

  function isMultiDate() {
    if (vm.item.date && vm.item.endDate) {
      if (vm.item.date.split('T')[0] !== vm.item.endDate.split('T')[0]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function isEvent() {
    return vm.item.type === 'events';
  }

  function isAllDay() {
    return $filter('date')(vm.item.date, 'H') === '0';
  }
}

