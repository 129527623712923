app.progressIndicator = (function($){
  'use strict';

  function init(){
    var winHeight = $(window).height(),
        docHeight = $(document).height(),
        progressBar = $('.progress-indicator'),
        max, value;

    /* Set the max scrollable area */
    max = docHeight - winHeight;
    progressBar.attr('max', max - 171);

    $(document).on('scroll', function(){
       value = $(window).scrollTop();
       progressBar.attr('value', value);
    });
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
