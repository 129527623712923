app.checkoutHeader = (function($){
  'use strict';


  function init(){

    const $button = $('.js-checkout-account-button');
    const $menu = $('#' + $button.attr('aria-controls'));

    $button.on('click', function() {
      const hidden = $menu.attr('aria-hidden');
      const newVal = hidden == 'true' ? 'false' : 'true';
      $menu.attr('aria-hidden', newVal);
    });
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
