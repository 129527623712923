app.reveal = (function($){
'use strict';

  function init(){
    const $button = $('.js-reveal');
    // console.log($button);
    $button.on('click', function(event) {
      event.preventDefault();
      const $this = $(this);
      const $target = $('#' + $this.attr('data-target') );

      $target.attr('aria-hidden', 'false').slideDown();

      $this.hide();
    });
  }
  $(document).on('ready', init);

})(jQuery);
