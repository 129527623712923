app.pagination = (function($){
  'use strict';

  function init(){
    var pagination = $('.js-pagination-trimmed'),
    paginationItems = $('> span', pagination).slice(1, -1),
    activeItem = $('.active', pagination);
    addPaginationClasses(paginationItems);
    var visibleItems = showActiveItems(activeItem);
    checkOverflow(pagination, visibleItems);
  }

  function addPaginationClasses(items) {
    items.addClass('is-hidden pagination__item');
  }

  function showActiveItems(activeItem) {
    var visibleItems = activeItem.nextAll('.pagination__item').slice(0, 5);
    activeItem.removeClass('is-hidden')
    visibleItems.removeClass('is-hidden');
    return visibleItems;
  }

  function checkOverflow(container, visibleItems) {
    if (visibleItems.length >= 4 ) {
      container.addClass('pagination--overflow');
    }
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
