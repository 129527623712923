app.imageCta = (function($){
  'use strict';

  function init() {
    $('.image-cta').each(function(){
      var $imageCta = $(this);
      var $caption = $('.image-cta__caption', $imageCta);
      var $mobileCaption = $caption.clone();
      $mobileCaption.addClass('image-cta__caption--mobile');
      $mobileCaption.insertAfter($('.image-cta__image', $imageCta));
    });

  }

  $(document).on('ready', init);

  return '';

})(jQuery);
