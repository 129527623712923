app.search = (function($) {
  'use strict';

  function init() {
    $('.js-search-field').on('focus', function(e) {
      var $search = $(this).closest('.search');
      var $suggestions = $search.find('.search__suggestions');
      $search.addClass('is-open');
      $suggestions.css({
        'max-height': $suggestions.find('ul').outerHeight()
      });
    }).on('keyup', function(e) {
      // Hitting "Esc" closes the module
      if (e.which === 27) {
        $(this).blur();
      }
    });

    //Close search boxes if clicked outside of them
    $(document).on('click', function(e) {
      var $search = $('.search.is-open');
      if (!$search.is(e.target) && $search.has(e.target).length === 0) {
        closeSearch();
      }
    }).on('keyup', function(e) {
      if (e.which === 27) {
        closeSearch();
      }
    });

    //When clicking on search button on unexpanded search module it expands and focuses on the input field
    $('.search__button').on('click', function(){
      var $search = $(this).closest('.search');
      if (!$search.hasClass('is-open')) {
        $search.find('.js-search-field').focus();
      }
    });

    var closeSearch = function() {
      var $search = $('.search.is-open');
      var $suggestions = $search.find('.search__suggestions');
      $search.removeClass('is-open');
      $search.find('.js-search-field').blur();
      $suggestions.css({
        'max-height': 0
      });
    };
  }


  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
