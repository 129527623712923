app.calloutColumns = (function($){
  'use strict';

  var calloutColumns = {

    init: function() {

      // Equal heights
      calloutColumns.equalHeight.init();

    },

    equalHeight: {

      els: '.callout-columns__items',

      init: function() {

        if ( !Modernizr.flexbox ) {

          // Cache items
          calloutColumns.equalHeight.els = $(calloutColumns.equalHeight.els);

          // If there's no flexbox use equalHeightcalloutColumns instead
          $(calloutColumns.equalHeight.els).equalHeightColumns({
            selector: '.callout-columns__item',
            outerHeight: true
          });

        }

      } // init

    } // equalHeight

  };

  $(document).on('ready', calloutColumns.init);

  return calloutColumns;

})(jQuery);
