'use strict';

angular.module('redlands')
  .component('rlMiniNewsEvents', {
    bindings: {
      source: '@source'
    },
    template: [
      '<a class="content-list__item" ng-href="{{item.url}}" ng-repeat="item in vm.items">',
      '<img src="{{::item.image}}" class="content-list__item-image" ng-if="item.image">',
      '<div class="content-list__item-content" ng-class="{\'has-image\': item.image}">',
        '<h4 class="content-list__item-headline">{{::item.title}}</h4>',
        '<div ng-bind-html="item.description | trust"></div>',
        '<p class="content-list__item-date" ng-if="item.type === \'events\'">',
            '<span class="month">{{::item.date | date : "MMM"}}</span> <span class="date">{{::item.date | date : "d"}}</span>',
        '</p>',
        '<p class="content-list__item-date" ng-if="item.type !== \'events\'">',
            '{{item.date | date : "mediumDate"}}',
        '</p>',
      '</div>',
      '</a>',

      '<div ng-show="vm.isLoading" class="l-padding--half-top"><div class="spinner"></div></div>',

      ].join(''),
    controller: rlMiniNewsEvents,
    controllerAs: 'vm'
  });

rlMiniNewsEvents.$inject = ['TabsService'];

function rlMiniNewsEvents(TabsService) {
  /* jshint validthis: true */
  var vm = this;
  vm.items = [];
  vm.itemsCount = 3;

  activate();

  /////////
  function activate() {
    //Loading module configuration
    TabsService.getSources(vm.source).success(function(data){
      vm.items = data.items.splice(0,3);
    });
  }



}



