app.image_content_blocks = (function($){
  'use strict';

  function init(){
    $('.image-content-block').each(function(i, obj) {
      if (i % 2 !== 0){
        $(this).children('.full-split__item--left').css('order',2);
      }
    });
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
