app.scrollLink = (function($){
  'use strict';

  function init(){

    var $docBody = $('html, body');
    $('.scroll-link').click(function() {
      var target = $.attr(this, 'href');
      $docBody.animate({
        scrollTop: $(target).offset().top
      }, 500, function () {
        window.location.hash = target;
      });
      return false;
    });

  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
