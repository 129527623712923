app.locationMap = (function($, breakpoints){
  'use strict';

  var map = {

    maps: '.location-map',
    totalMaps: 0,
    totalMapsEffected: 0,
    template: '<a href="https://www.google.com/maps?q=loc:{{lat}},{{long}}" class="location-map__link"><img src="http://maps.google.com/maps/api/staticmap?zoom=16&markers={{lat}},{{long}}&size=400x400&scale=2" height="400" width="400" class="location-map__image" alt="Map to campus" /></a>',

    init: function() {

      // Cache elements
      map.maps = $(map.maps);

      // Check and cache the number of maps with photos
      map.maps.each(function(){
        if ( $(this).data('rlMapLat') && $(this).data('rlMapLong') ) {
          map.totalMaps++;
        }
      });

      // Check if maps are visible
      map.checkVisibleItems();

      //  Bind a debounced scroll for further checking
      $(window).on('scroll.redlands.map', map.debouncedCheck);

    },

    debouncedCheck: debounce(function() {
        map.checkVisibleItems();
      }, 250),

    checkVisibleItems: function() {

      // Check each map individually
      map.maps.each(function() {

        var item = $(this);

        // Check if the element is in view
        if ( almostVisible(item) ) {
          map.createMap(item);
        }

      });

    },

    createMap: function(element) {

      var created = element.data('rlRowMapCreated');

      if ( !created ) {

        // Get lat/long, tempalte
        var lat = element.data('rlMapLat'),
            long = element.data('rlMapLong'),
            template = map.template;

        // Create map
        template = template.replace(/{{lat}}/g, lat);
        template = template.replace(/{{long}}/g, long);
        element.append($(template));

        // Set indicator
        element.data('rlRowMapCreated', true);

        // Increment the effected counter
        map.totalMapsEffected++;

      }

      // If all of the items have been effected, unbind the scroll event
      if ( map.totalMaps === map.totalMapsEffected ) {
        $(window).off('scroll.redlands.map', map.debouncedCheck);
      }

    }

  };

  $(document).on('ready', map.init);

  return map;

})(jQuery, app.breakpoints);
