(function() {
  'use strict';

  angular
    .module('redlands')
    .factory('TabsService', TabsService);

  TabsService.$inject = ['$http'];

  function TabsService($http) {
    var service = {
    getSources: getSources,
    getItems: getItems,
    filterItems: filterItems
    };
    return service;

    ////////////////

    function getSources(url) {
      return $http.get(url);
    }
    function getItems(tab) {
      return $http.get(tab.source);
    }
    function filterItems(tab, filter) {
      return $http.post(tab.source, filter);
    }
  }
})();
