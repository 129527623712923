app.sectionSubnav = (function($){
  'use strict';

  function init(){
    $('.section-nav__item').each(function() {
      if ( $(this).find('.section-nav__child-items').length ) {
       $(this).addClass('has-children');
      }
    });
    $('.subnav-toggle').on('click', function(e){
      e.preventDefault();
      $(this).parent().find('.section-nav__child-items').fadeToggle();
      $(this).toggleClass('open');
    })
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
