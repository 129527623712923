app.more_cta_cue = (function($){
  'use strict';

  function init(){
    $('.more-cta-cue').click(function() {
      $('html,body').animate({
          scrollTop: $('.feature-tabs').offset().top},
          'slow');
      })
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
