app.row = (function($, breakpoints){
  'use strict';

  var row = {

    rows: '.row',
    totalRows: 0,
    totalRowsEffected: 0,

    init: function() {

      // Cache elements
      row.rows = $(row.rows);

      // Check and cache the number of rows with photos
      row.rows.each(function(){
        if ( $(this).data('rlRowPhoto') && $(this).data('rlRowPhoto').length ) {
          row.totalRows++;
        }
      });

      if ( row.totalRows > 0 ) {

        // Check if rows are visible
        row.checkVisibleItems();

        //  Bind a debounced scroll for further checking
        $(window).on('scroll.redlands.row', row.debouncedCheck);

      }

    },

    debouncedCheck: debounce(function() {
        row.checkVisibleItems();
      }, 250),

    checkVisibleItems: function() {

      // Check each row individually
      row.rows.each(function() {

        var item = $(this);

        // Check if the element is in view
        if ( almostVisible(item) ) {
          row.placeImage(item);
        }

      });

    },

    placeImage: function(element) {

      var photo = element.data('rlRowPhoto'),
          placedPhoto = element.data('rlRowPhotoPlaced');

      // If there's a photo and we haven't already placed a photo, have at 'er
      if ( photo && !placedPhoto ) {

        // Place photo and save placed indicator as data
        element.append('<div class="row__photo" style="background-image: url(' + photo + ');" />');
        element.data('rlRowPhotoPlaced', true);
        element.addClass('has-photo');

        // Increment the effected counter
        row.totalRowsEffected++;

      }

      // If all of the items have been effected, unbind the scroll event
      if ( row.totalRows === row.totalRowsEffected ) {
        $(window).off('scroll.redlands.row', row.debouncedCheck);
      }

    }

  };

  $(document).on('ready', row.init);

  return row;

})(jQuery, app.breakpoints);
