app.fullsplit = (function($, breakpoints){
  'use strict';

  var fullsplit = {

    init: function() {

      // Equal heights
      fullsplit.equalHeight.init();

    },

    equalHeight: {

      els: '.full-split',

      init: function() {

        if ( !Modernizr.flexbox ) {

          // Cache items
          fullsplit.equalHeight.els = $(fullsplit.equalHeight.els);

          // If there's no flexbox use equalHeightfullsplit instead
          $(fullsplit.equalHeight.els).equalHeightColumns({
            selector: '.full-split__item',
            outerHeight: true
          });

          Harvey.attach('screen and (max-width: ' + breakpoints.medium + 'px)', {
            on: function() {
              fullsplit.equalHeight.clearHeight();
            },
            off: function() {
              fullsplit.equalHeight.setHeight();
            }
          });

        }

      }, // init

      setHeight: function() {
        $(fullsplit.equalHeight.els).equalHeightColumns('refresh');
      }, // setHeight

      clearHeight: function() {
        $(fullsplit.equalHeight.els).equalHeightColumns('kill');
      } // clearHeight

    } // equalHeight

  };

  $(document).on('ready', fullsplit.init);

  return fullsplit;

})(jQuery, app.breakpoints);
