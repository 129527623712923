app.contentPane = (function($){
  'use strict';

  function init(){

     $('.content-pane').each(function(){
      if($(this).length) {
        var photo = $(this).data('rlFeaturePhoto');
        $(this).css('background-image', 'url(' + photo + ')');
      }
    });

  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
