app.balancetext = (function($){
  'use strict';

  function init(){
    $('.block-links--stacked a').balanceText();
    $('.proof-points--centered .media__content > *').balanceText();
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
