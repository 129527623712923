app.tables = (function($){
  'use strict';

  // Super wide tables were bleeding outside parent containers on desktop
  $('.table, .wysiwyg table').each(function(){
    // Get Parent width
    var tableParentWidth = $(this).parent().width();

    // Wrap table element in floated container to accruately
    // get width outside of parent container
    $(this).wrap('<span class="width-wrapper" style="overflow: hidden;float: left;"></span>');

    var tableWidth = $(this).closest('.width-wrapper').width();

    // If table is wider then parent add overflow class
    if ( tableWidth > tableParentWidth ) {
      $(this).addClass('table--overflow');
    }

    // Remove .width-wrapper
    $(this).unwrap();
  });
})(jQuery);
