app.imageTile = (function($){
  'use strict';

  var imageTile = {

    imageTiles: '.image-tile',
    totalimageTiles: 0,
    totalimageTilesEffected: 0,

    init: function() {

      // Cache elements
      imageTile.imageTiles = $(imageTile.imageTiles);

      // Check and cache the number of imageTiles with photos
      imageTile.imageTiles.each(function(){
        if ( $(this).data('rlImageTilePhoto') && $(this).data('rlImageTilePhoto').length ) {
          imageTile.totalimageTiles++;
        }
      });

      // Check if imageTiles are visible
      imageTile.checkVisibleItems();

      //  Bind a debounced scroll for further checking
      $(window).on('scroll.redlands.imageTile', imageTile.debouncedCheck);

    },

    debouncedCheck: debounce(function() {
        imageTile.checkVisibleItems();
      }, 250),

    checkVisibleItems: function() {

      // Check each imageTile individually
      imageTile.imageTiles.each(function() {

        var item = $(this);

        // Check if the element is in view
        if ( almostVisible(item) ) {
          imageTile.placeImage(item);
        }

      });

    },

    placeImage: function(element) {

      var photo = element.data('rlImageTilePhoto'),
          placedPhoto = element.data('rlImageTilePhotoPlaced');

      // If there's a photo and we haven't already placed a photo, have at 'er
      if ( photo && !placedPhoto ) {

        // Place photo and save placed indicator as data
        element.css('background-image', 'url(' + photo + ')');
        element.data('rlImageTilePhotoPlaced', true);
        element.addClass('has-photo');

        // Increment the effected counter
        imageTile.totalimageTilesEffected++;

      }

      // If all of the items have been effected, unbind the scroll event
      if ( imageTile.totalimageTiles === imageTile.totalimageTilesEffected ) {
        $(window).off('scroll.redlands.imageTile', imageTile.debouncedCheck);
      }

    }

  };

  $(document).on('ready', imageTile.init);

  return imageTile;

})(jQuery);
