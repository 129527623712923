'use strict';

angular.module('redlands', ['ngAnimate'])
  .filter('getActive', getActive)
  .filter('trust', trust);


// Some global filters

function getActive() {
  return function(input, id) {
    var i=0, len=input.length;
    for (; i<len; i++) {
      if (input[i].isActive) {
        return input[i];
      }
    }
    return null;
  };
}

trust.$inject = ['$sce'];
function trust($sce) { return $sce.trustAsHtml; }




