'use strict';

angular.module('redlands')
  .component('rlProgramItem', {
    bindings: {
      program: '=rlProgramItem'
    },
    template: ['<a ng-href="{{::vm.program.url}}" class="grid-list__item grid-list__link">',
            '<ul class="grid-list__content">',
              '<li class="grid-list__image"><img src="{{::vm.program.image}}" /></li>',
              '<li class="grid-list__title">{{::vm.program.title}}</li>',
              '<li class="grid-list__subtitle">{{::vm.program.division}}</li>',
              '<li class="grid-list__body" ng-bind-html="vm.program.description | trust"></li>',
            '</ul></a>'].join(''),
    controller: rlProgramItem,
    controllerAs: 'vm'
  });

rlProgramItem.$inject = [];

function rlProgramItem(){
  var vm = this;
}
