app.facts = (function($, breakpoints){
  'use strict';

  var facts = {

    mainColumnItems: '.l-content .facts, .l-full-content .facts',
    sidebarItems: '.l-content__sidebar .facts',

    init: function() {

      // Cache items
      facts.mainColumnItems = $(facts.mainColumnItems);
      facts.sidebarItems = $(facts.sidebarItems);

      // Size items in main content area
      $(facts.mainColumnItems).equalHeightColumns({
        selector: '.facts__item',
        outerHeight: true
      });

      // Size sidebar items IF there's no flexbox support
      if ( !Modernizr.flexbox ) {
        $(facts.sidebarItems).equalHeightColumns({
          selector: '.facts__item',
          outerHeight: true
        });
      }

      Harvey.attach('screen and (max-width: ' + breakpoints.small + 'px)', {
        on: function() {
          facts.clearSizes();
        },
        off: function() {
          facts.setSizes();
        }
      });

    },

    setSizes: function() {
      facts.mainColumnItems.equalHeightColumns('refresh');
    },

    clearSizes: function() {
      if ( Modernizr.flexbox ) {
        facts.mainColumnItems.equalHeightColumns('kill');
      }
    }

  };

  $(document).on('ready', facts.init);

  return facts;

})(jQuery, app.breakpoints);
