app.featureTabs = (function($, breakpoints){
  'use strict';

  var featureTabs = {

    elements: '.feature-tabs',

    init: function() {

      // Cache elements
      featureTabs.elements = $(featureTabs.elements);

      // Watch resize, and also run the resize logic off the bad
      featureTabs.resizeLogic();
      $(window).smartresize(featureTabs.resizeLogic, 150);

    }, // init


    refreshElements: function() {
      featureTabs.elements = $(featureTabs.elements);
    },

    isScrollable: function(that) {

      // Figure out some dimensions
      var scrollWidth = that.find('.feature-tabs__items')[0].scrollWidth,
          elementWidth = that.width(),
          isScrollable = scrollWidth > elementWidth;

      // Return true/false whether or not the the scrollable area is larger than the container
      return isScrollable;

    }, // isScrollable

    resizeLogic: function() {

      // Only do this if we're at the small size
      if ( Modernizr.mq('(max-width: ' + breakpoints.small + 'px)') ) {
        // Check if each item is scrollable
        featureTabs.elements.each(function(event) {
          if ( featureTabs.isScrollable($(this)) ) {
            featureTabs.makeScrollable($(this));
            featureTabs.fitWidths($(this), true);
          } else {
            $(this).removeClass('is-scrollable');
            featureTabs.destroyScrollable($(this));
            featureTabs.fitWidths($(this));
          }
        });

      } else {
        if ( featureTabs.elements.data('rlFeatureTabsSized') ) {
          featureTabs.elements.divideWidth('kill').data('rlFeatureTabsSized', false);
        }
      }

    }, // resizeLogic

    fitWidths: function(that, kill) {
      // Give kill a default value
      if ( typeof(kill) === 'undefined' ) {
        kill = false;
      }

      // Fit the items, and set a bool in data
      if ( !kill && !that.data('rlFeatureTabsSized') ) {
        that.divideWidth({
          selector: '.feature-tabs__item'
        }).data('rlFeatureTabsSized', true);
      }
    }, // fitWidths

    makeScrollable: function(that) {
      that.addClass('is-scrollable');
      if (!that.hasClass('scrollable-initialized')) {
        var $left = $('<button class="feature-tabs__scroll-icon feature-tabs__scroll-icon--left">');
        var $right = $('<button class="feature-tabs__scroll-icon feature-tabs__scroll-icon--right">');

        $right.on('click tap', function(){
          that.find('.feature-tabs__inner').animate({
            scrollLeft: '+=' + $(window).width() * 0.7
          }, 200);
        });

        $left.on('click tap', function(){
          that.find('.feature-tabs__inner').animate({
            scrollLeft: '-=' + $(window).width() * 0.7
          }, 200);
        });

        that.prepend($left, $right);
        that.addClass('scrollable-initialized');
      }
    },

    destroyScrollable: function(that) {
      that.removeClass('is-scrollable');
    }

  };


  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', featureTabs.init);

  return featureTabs;

})(jQuery, app.breakpoints);
