app.checkout = (function($){
  'use strict';

  function init(){
    console.log('checkout!');
    const $checkbox = $('.order-summary #terms-checkbox');
    const $target = $('.js-proceed-target');
    // console.log($checkbox);

    const $addressToggle = $('.js-address-toggle');
    // const $addressOptions = $('.address-select__options');

    $checkbox.on('change', function(event) {
      event.preventDefault();
      const $this = $(this);

      if ( $this.is(':checked')) {
        $target.removeAttr('disabled');
        $target.removeClass('button--disabled');
      } else {
        $target.attr('disabled');
        $target.addClass('button--disabled');
      }
    });


    // toggle control
    console.log($addressToggle);
    $addressToggle.on('click', function(event) {
      event.preventDefault();
      const $this = $(this);
      console.log('click!', $this);

      const id = $this.attr('aria-controls');
      const $target = $('#' + id);
      const isHidden = $target.attr('aria-hidden');

      $this.parent('.address-select__current').toggleClass('js-is-hidden');

      $target.attr('aria-hidden', !isHidden );
    });

    // const $sectionButton = $('.js-checkout-section');

    // $sectionButton.on('click', function(event) {
    //   event.preventDefault();
    //   const $this = $(this);
    //   const $next = $this.next('.checkout-section__content');

    //   $next.slideToggle();
    // })
  }


  $(document).on('ready', init);

})(jQuery);
