app.pageNav = (function($){
  'use strict';

  function init(){
    var $pageNav = $('.page-nav');

    if ( $pageNav.length ) {
        var navOffset = updateNavOffset(),
        $docBody = $('body'),
        fixedClass = 'is-fixed-nav',
        closedClass = 'is-closed',
        $stickyContainer = $('.l-sticky');

      $(window).on('resize', function () {
        navOffset = updateNavOffset();
      });

      $(window).on('scroll', function () {
          var top = $(window).scrollTop(),
            divBottom = $stickyContainer.offset().top + $stickyContainer.outerHeight();
          if (divBottom > top) {
            $docBody.removeClass(fixedClass);
            $pageNav.css('margin-left', '0px');
            $stickyContainer.prepend($pageNav);
            $pageNav.removeClass(closedClass);
          } else {
            $pageNav.css('margin-left', navOffset + 'px');
            $docBody.addClass(fixedClass);
            $docBody.prepend($pageNav);
            $pageNav.addClass(closedClass);
          }
      });

      $('.page-nav__toggle').click(function(e) {
        e.preventDefault();
        $pageNav.toggleClass(closedClass);
      });
    }

    function updateNavOffset() {
      var siteWidth = $('.l-container.l-main').width()
      return ((siteWidth / 2) - 11) * -1;
    }
  }



  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
