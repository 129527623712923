app.locations = (function($, breakpoints){
  'use strict';

  var locations = {

    els: '.js-locations',
    maps: [],
    coords: [],
    markers: [],
    bounds: [],
    defaultLocations: [],
    bootstrapped: false,

    init: function() {

      // Cache elements
      locations.els = $(locations.els);

      // Kickoff the whole shebang
      locations.bootstrapIfShould();

      // Create directions links
      locations.createDirectionsLinks();

      // Initialize panelGroup
      locations.els.find('.locations__menu').panelGroup({
        type: 'accordion',
        firstAccordionOpen: false,
        selectors: {
            item: '.panel-group__item',
            header: '.panel-group__header',
            content: '.panel-group__content'
          }
      });

      // Harvey, in case you load the page with a small screen (no map) and size up
      Harvey.attach('screen and (min-width: ' + (breakpoints.small + 1) + 'px)', {
        on: function() {
          locations.bootstrapIfShould();
          locations.triggerResizeOnAllMaps();
        }
      });

    },


    /*
     * Returns true/false depending on whether or not the maps should get bootstrapped
     */
    shouldBootstrap: function() {

      // Only do anything if there are elements and we haven't already bootstrapped
      if ( locations.els.length && !locations.bootstrapped ) {

        // If there's no media query support, for sure do the maps
        if ( !Modernizr.mq('only all') ) {
          return true;
        // If we're not on the smallest size, bootstrap maps
        } else if ( Modernizr.mq('only all and (min-width: ' + (breakpoints.small + 1) + 'px)') ) {
          return true;
        } else {
          return false;
        }

      } else {
        return false;
      }

    },


    /**
     * Bootstrap the maps, but only if the check passes
     */
    bootstrapIfShould: function() {

      if ( locations.shouldBootstrap() ) {
        locations.bootstrap();
      }

    },


    /**
     * The meat and potatoes of the operation. This kicks off everything else
     */
    bootstrap: function() {

      // Indicate that we've run the bootstrap method
      locations.bootstrapped = true;

      // Load the google maps api
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=app.locations.buildMaps',
        dataType: 'script'
      });

      // Watch item change and centre map
      locations.els.each(function(index){

        $(this).find('.panel-group').on('accordionchange', function(){

          // Find active item
          var activeItem = $(this).find('.active');

          // Centre the map on the new item if there is one, or zoom out all the way
          if ( activeItem.length ) {
            locations.centerMap(activeItem, $(this), index);
          } else {
            locations.fitBounds(index);
          }

        });

      });

    },


    /**
     * Look through the locations and dig out lat/long pairs and find defaults
     */
    parseLocations: function() {

      // Loop through each item, allowing for multiple maps
      locations.els.each(function(mapIndex) {

        // Store found coords for this map group
        var coords = [];

        // Initial value for defaultLocation
        locations.defaultLocations[mapIndex] = false;

        // Find the items in the map
        $(this).find('.locations__item').each(function(index){

          // Save the lat/long
          var lat = $(this).data('rlMapLat'),
              long = $(this).data('rlMapLong');

          coords.push(new google.maps.LatLng(lat, long));

          // Check if this is the default item
          if ( $(this).data('rlMapDefault') ) {
            locations.defaultLocations[mapIndex] = index;
          }

        }); // each item

        // Add coords from this map to the main array of coordinates
        locations.coords[mapIndex] = coords;

      }); // each locations

    }, // parseLocations


    /**
     * Create google maps directions links from provided lat/long
     */
    createDirectionsLinks: function() {

      // Loop through maps
      locations.els.each(function() {

        // Loop through items
        $(this).find('.locations__item').each(function() {

          // Save the lat/long
          var lat = $(this).data('rlMapLat'),
              long = $(this).data('rlMapLong');

          // Create maps link
          var mapUrl = 'http://maps.google.com/maps?q=' + lat + ',' + long;

          // Swap link
          $(this).find('.js-locations-directions').attr('href', mapUrl);

        });

      });

    },


    /**
     * Look throuhg markup and find placeholder elements for maps
     */
    findMaps: function() {

      // Loop through each item, allowing for multiple maps
      locations.els.each(function(index) {

        // Add map placeholder to maps array
        var map = $(this).find('.locations__map');
        locations.maps[index] = map[0];

      });

     },


    /**
     * Main call to the Google maps API to build maps
     */
    buildMaps: function() {

      // Find map placeholder
      locations.findMaps();

      // Find location coordinates from markup
      locations.parseLocations();

      // Create a new maps instance for each item matched. Probably will only be one, but putting in support for multiple map groups
      locations.els.each(function(mapIndex){

        var mapConfig = {
          zoom: 16,
          center: locations.coords[mapIndex][0],
          panControl: false,
          backgroundColor: 'false',
          mapTypeControl: false,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL
          }
        };

        // Instantiate the map
        locations.maps[mapIndex] = new google.maps.Map(locations.maps[mapIndex], mapConfig);

        // Place pins
        locations.placePins(mapIndex);

        // Zoom to default location, or show all if there's no default
        if ( locations.defaultLocations[mapIndex] ) {
          locations.openInfo(mapIndex, locations.defaultLocations[mapIndex]);
        } else {
          locations.fitBounds(mapIndex);
        }

      });

    },  // buildMaps


    /**
     * Place pins on each map
     */
    placePins: function(mapIndex) {

      // Create array for markers and object for bounds
      locations.markers[mapIndex] = [];
      locations.bounds[mapIndex] = new google.maps.LatLngBounds();

      // Add markers to the map at mapIndex, set bounds
      for ( var i = 0; i < locations.coords[mapIndex].length; i++ ) {

        // Add marker
        var marker = new google.maps.Marker({
          position: locations.coords[mapIndex][i],
          map: locations.maps[mapIndex]
        });

        // Save marker in main array
        locations.markers[mapIndex].push(marker);

        // Update bounds with current item
        if ( i < locations.coords[mapIndex].length - 1 ) {
          locations.bounds[mapIndex].extend(locations.coords[mapIndex][i]);
        }

        // Click handler for pins
        (function(i, marker){
          google.maps.event.addListener(marker, 'click', function(){
            locations.openInfo(mapIndex, i);
          });
        })(i, marker);

      } // for

    },


    centerMap: function(active, menu, mapIndex, index) {

      var itemIndex;
      active = typeof(active) === 'undefined' ? false : active,
      menu = typeof(menu) === 'undefined' ? false : menu;

      if ( active && menu ) {
        itemIndex = menu.find('.locations__item').index(active[0]);
      } else if ( index ) {
        itemIndex = index;
      } else {
        return;
      }

      // Pan to item
      locations.maps[mapIndex].panTo(locations.coords[mapIndex][itemIndex]);

      // Set zoom
      locations.maps[mapIndex].setZoom(16);

      // Trigger resize
      google.maps.event.trigger(locations.maps[mapIndex], 'resize');

    },


    /**
     * Center the map on the whole shebang
     */
    fitBounds: function(mapIndex) {
      google.maps.event.trigger(locations.maps[mapIndex], 'resize');
      locations.maps[mapIndex].fitBounds(locations.bounds[mapIndex]);
    },


    /**
     * Open accordion item for location
     */
    openInfo: function(mapIndex, itemIndex) {

      // Open the appropriate nav item
      var chosenItem = $(locations.els[mapIndex]).find('.panel-group__item:eq(' + itemIndex + ')');

      if ( !chosenItem.is('.active') ) {
        chosenItem.find('.panel-group__header a').click();
      }

      // Centre map on the new item
      locations.centerMap(false, false, mapIndex, itemIndex);

    },


    /**
     * Trigger resize for all accordions. Useful if screen goes small > large > small > large
     * so the map is sized propertly.
     */
     triggerResizeOnAllMaps: function() {
      if ( locations.bootstrapped ) {
        for (var i = locations.maps.length - 1; i >= 0; i--) {
          locations.fitBounds(i);
        }
      } // if

     } // triggerResizeOnAllMaps

  };

  $(document).on('ready', locations.init);

  return locations;

})(jQuery, app.breakpoints);
