app.datepicker = (function($){
  'use strict';

  function init() {

    $('.js-datepicker').pikaday();

  } // init

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
