app.panelGroup = (function($, breakpoints){
  'use strict';

  var groups;

  function init() {

    groups = $('.js-panel-group');

    groups.panelGroup({
      flexTabNav: false,
      tabNavAsList: false,
      selectors: {
          item: '.panel-group__item',
          header: '.panel-group__header',
          content: '.panel-group__content'
        }
    });

  }

  $(document).on('ready', init);

  Harvey.attach('screen and (max-width: ' + breakpoints.small + 'px)', {
    on: function() {
      // groups.panelGroup('tabsToAccordions');
    },
    off: function() {
      // groups.panelGroup('tabsBackToTabs');
    }
  });

  return '';

})(jQuery, app.breakpoints);
