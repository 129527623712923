app.testimonialsAlt = (function($, breakpoints) {
  'use strict';

  function init() {
    if (!$('.testimonials-alt').length) {
      return;
    }

    $('.testimonials-alt').each(initTestimonials);

    //Prev / next arrows binding
    $('.js-testimonials-alt-prev, .js-testimonials-alt-next').on('click', function(e) {
      e.preventDefault();
      var $testimonials = $(this).closest('.testimonials-alt');
      var owl = $testimonials.find('.owl-carousel').data('owlCarousel');
      if ($(this).hasClass('js-testimonials-alt-prev')) {
        owl.prev();
      } else {
        owl.next();
      }
    });
  }

  function updateTestimonials($testimonials) {
    var $testimonialsContainer = $('.testimonials-alt__container', $testimonials);
    var $testimonialsSlider = $('.testimonials-alt__slider', $testimonials);
    $testimonialsSlider.css('margin-top', '');

    if ($(window).width() > breakpoints.mid) {
      if ($testimonialsSlider.height() > 0) {
        if ($testimonialsContainer.height() >  $testimonialsSlider.height()) {
          $testimonialsSlider.css('margin-top', ($testimonialsContainer.height() - $testimonialsSlider.height()) / 2 );
        }
      } else {
        //If the image slider hasn't initialized, wait till it's done and check back in 200ms
        setTimeout(function(){
          updateTestimonials($testimonials);
        }, 200);
      }
    }
  }

  function initTestimonials(index, testimonials) {
    var $testimonials = $(testimonials);
    var $testimonialsContainer = $('.testimonials-alt__container', $testimonials);
    var $itemsContainer = $('.testimonials-alt__items', $testimonials);
    var $items = $(' .testimonials-alt__item', $itemsContainer);
    var $owlCarousel = $testimonials.find('.owl-carousel');
    var $imageSlider = $testimonials.find('.slider__images');
    var $sliderNav = $('<div class="testimonials-alt__nav">');


    var owl = $owlCarousel.owlCarousel({
      singleItem: true,
      pagination: false,
      itemsScaleUp: true,
      transitionStyle: 'fade',
      afterMove: function($owl) {
        var owl = this;

        //Checking if image is on another slide. Advance to it
        var imageSliderOwl = $imageSlider.data('owlCarousel');
        if (owl.currentItem !== imageSliderOwl.currentItem) {
          imageSliderOwl.goTo(owl.currentItem);
        }
      },
      afterInit: function($owl) {
        updateTestimonials($owl.closest('.testimonials-alt'));
      },
      afterUpdate: function($owl) {
        updateTestimonials($owl.closest('.testimonials-alt'));
      }
    });

    if ($items.length > 1) {
      $sliderNav.append('<a href="#" class="slider__arrow slider__arrow--prev js-testimonials-alt-prev"></a><a href="#" class="slider__arrow slider__arrow--next js-testimonials-alt-next"></a>');
      $testimonialsContainer.append($sliderNav);
    }

  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, app.breakpoints);