'use strict';

angular.module('redlands')
  .component('rlNewsEvents', {
    bindings: {
      source: '@source'
    },
    template: ['<div class="feature-tabs" ng-show="vm.tabs.length > 1">',
      '<div class="feature-tabs__inner">',
      '<nav class="feature-tabs__items">',
      '<a ng-repeat="tab in vm.tabs" ng-click="vm.activateTab(tab)" class="feature-tabs__item" ng-class="{\'is-active\': vm.isActive(tab)}">{{tab.title}}</a>',
      '</nav></div></div>',

      '<div class="tab-content tab-content--dynamic">',

      '<div class="form grid-form">',
        '<form>',
          '<div class="form__group grid-form__input">',
            '<div class="form-select__wrapper"><select name="program-search-type" id="program-search" class="form__control form-select" ng-model="vm.selectedCategory" ng-change="vm.runFilter()" ng-options="category as category.title for category in vm.categories track by category.id">',
              '<option value="" selected>{{vm.activeTab.title}} category</option>',
            '</select></div>',
          '</div>',
          '<div class="form__group grid-form__input">',
            '<div class="search">',
              '<div class="search__field-group">',
                '<input type="text" name="search" placeholder="Search {{vm.activeTab.title | lowercase}}" class="form__control search__field" ng-model="vm.searchFilter.search" ng-change="vm.runFilter()" ng-model-options="{ updateOn: \'default blur\', debounce: { \'default\': 1000, \'blur\': 0 } }">',
                '<button class="search__button form__control">Search</button>',
              '</div>',
            '</div>',
          '</div>',
          '<div class="form__group grid-form__input">',
            '<div class="search__field-group datepicker">',
                '<input type="text" name="search" placeholder="Search by date" class="form__control js-datepicker" autocomplete="off" ng-model="vm.dateField" ng-change="vm.setFilterDate(vm.dateField)">',
            '</div>',
          '</div>',
        '</form>',
        '<p class="grid-form__summary ng-anim--fadeup" ng-if="vm.isFiltered()">Your search returned {{vm.filteredCount}} results. <a ng-click="vm.resetFilter()">Reset search filters</a>.</p>',
      '</div>',



      '<div class="columns columns--equal-height columns--4 content-grid" ng-class="vm.gridModifier()">',
      '<rl-news-item ng-repeat="item in vm.items" rl-news-item="item" class="columns__item content-grid__item ng-anim--fadeup"></rl-news-item>',
      '</div>',

      '<div ng-if="vm.hasMore() && !vm.isLoading" class="button__wrapper l-padding--half-top">',
        '<a ng-click="vm.loadMore()" class="button">Load more</a>',
      '</div>',


      '<div ng-show="vm.isLoading" class="l-padding--half-top"><div class="spinner"></div></div>',

      '</div>',
      '<ng-transclude></ng-transclude>'
      ].join(''),
    controller: rlNewsEvents,
    controllerAs: 'vm',
    transclude: true
  });

rlNewsEvents.$inject = ['$element', 'TabsService', '$filter', '$timeout'];

function rlNewsEvents($element, TabsService, $filter, $timeout) {
  /* jshint validthis: true */
  var vm = this;
  vm.activateTab = activateTab;
  vm.isActive = isActive;
  vm.resetFilter = resetFilter;
  vm.runFilter = runFilter;
  vm.hasMore = hasMore;
  vm.loadMore = loadMore;
  vm.setFilterDate = setFilterDate;
  vm.filteredResponse = false;
  vm.activeTab;
  vm.selectedCategory;
  vm.isFiltered = isFiltered;
  vm.gridModifier = gridModifier;
  vm.searchFilter = {};
  vm.items = [];

  activate();

  /////////
  function activate() {
    //Loading module configuration
    TabsService.getSources(vm.source).success(function(data){
      vm.tabs = data;
      //Search for active, if none found activate first
      activateTab($filter('getActive')(vm.tabs) || vm.tabs[0]);

      //Initializing feature tabs after digest cycle (feature-tabs.js) so it's scrollable on smaller screens
      $timeout(function(){
        app.featureTabs.refreshElements();
        app.featureTabs.resizeLogic();
      });
    });
  }

  function activateTab(tab) {
    if (tab !== vm.activeTab) {
      vm.activeTab = tab;
      resetFilterFields();
      vm.items = [];
      if (tab.source) {
        $element.find('.tab-content--static').removeClass('is-active');
        $element.find('.tab-content--dynamic').addClass('is-active');
        getItems();
      } else if (tab.tabSelector) {
        $element.find('.tab-content--dynamic').removeClass('is-active');
        $element.find(tab.tabSelector).addClass('is-active');
        vm.totalCount = vm.filteredCount = 0;
      }
    }
  }

  function isActive(tab) {
    return tab === vm.activeTab;
  }

  function hasMore() {
    return vm.items.length < vm.totalCount && (vm.filteredCount !== 0);
  }

  function getItems() {
    vm.items = [];
    vm.isLoading = true;
    vm.filteredResponse = false;
    vm.isLoading = true;

    TabsService.getItems(vm.activeTab).then(function(response){
      //Checking if the returned data is for this tab
      if (vm.activeTab.source === response.config.url) {
        vm.items = vm.items.concat(response.data.items);
        vm.categories = response.data.categories;
        vm.totalCount = response.data.totalCount;
        vm.isLoading = false;
      }
    });
  }

  function loadMore() {
    vm.searchFilter.limit = 8;
    vm.searchFilter.offset = vm.items.length;
    vm.isLoading = true;
    TabsService.filterItems(vm.activeTab, vm.searchFilter).then(function(response){
      //Checking if the returned data is for this tab
      if (vm.activeTab.source === response.config.url) {
        vm.items = vm.items.concat(response.data.items);
        vm.categories = response.data.categories;
        vm.filteredCount = response.data.totalCount;
        vm.totalCount = response.data.totalCount;
        vm.isLoading = false;
        vm.filteredResponse = true;
      }
    });
  }

  function resetFilter(){
    resetFilterFields();
    getItems();
  }

  function resetFilterFields(){
    vm.searchFilter = {};
    vm.selectedCategory = null;
    vm.dateField = null;
  }

  function setFilterDate(date) {
    if (moment(date) < moment().startOf('day')) {
      //When searching for past events we query for 500 days back and ordering by newest first
      vm.searchFilter.end = moment(date).format('M/D/YYYY');
      vm.searchFilter.start = moment(date).subtract(500, 'days').format('M/D/YYYY');
      vm.searchFilter.order = 'DESC';
    } else {
      //When searching for upcoming events we query 60 days forward and ordering by nearest upcoming first
      vm.searchFilter.start = moment(date).format('M/D/YYYY');
      vm.searchFilter.end = moment(date).add(60, 'days').format('M/D/YYYY');
      vm.searchFilter.order = 'ASC';
    }
    runFilter();
  }

  function runFilter(){
    //Reset items and initialize filter
    vm.items = [];

    //If category is set, put it's id into filter
    if (vm.selectedCategory) {
      vm.searchFilter.categoryId = vm.selectedCategory.id || '';
    } else if (vm.searchFilter.categoryId) {
      //If not, remove from filter
      delete vm.searchFilter.categoryId;
    }
    if (vm.activeTab.title === 'Events' && vm.searchFilter.categoryId && vm.searchFilter.search) {
      //Events api requires categoryId to be array when used together with search
      vm.searchFilter.categoryId = [vm.searchFilter.categoryId];
    }
    if (vm.searchFilter.search && !vm.dateField) {
      //If we started searching but didn't pick a date, let's remove start and end dates
      delete vm.searchFilter.start;
      delete vm.searchFilter.end;
    }
    loadMore();
  }

  function isFiltered() {
    //Deciding to show search results count
    return !angular.equals(vm.searchFilter, {}) && vm.filteredResponse;
  }

  function gridModifier() {
    if (vm.activeTab) {
      return 'content-grid--' + vm.activeTab.title.toLowerCase();
    } else {
      return '';
    }

  }

}



