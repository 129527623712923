app.anchorNav = (function($){
  'use strict';

  function footerIsVisible() {
    if ( $(window).scrollTop() + $(window).height() <= $('.site-footer').offset().top ) {
      return true;
    } else {
      return false;
    }
  }

  function init(){

    var siteFooter = $('.site-footer'),
        anchorNav = $('.anchor-nav');

    // initiate appear plugin
    siteFooter.appear({
      interval: 50
    });

    // avoid flash of incorrectly positioned content

    // If the page is unscrolled
    // Could be updated to check for .site-footer visibility?
    if ( footerIsVisible() ) {
      anchorNav.addClass('is-anchored is-visible');
    } else {
      anchorNav.addClass('is-visible');
    }

    // Watch document scroll - remove anchored class when
    // .site-footer is in viewport
    $(document.body).on('appear', siteFooter, function(e) {
      anchorNav.removeClass('is-anchored');
    });

    // Re-Anchor nav when footer is out of viewport
    $(document.body).on('disappear', siteFooter, function(e) {
      anchorNav.addClass('is-anchored');
    });
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery);
