app.deepDive = (function($, breakpoints){
  'use strict';

  var deepDive = {

    elements: '.js-deep-dive',

    init: function() {

      // Cache items
      deepDive.elements = $(deepDive.elements);

      // Init plugin
      deepDive.elements.dropkick({
        mobile: false
      });

    } // init

  };

  $(document).on('ready', deepDive.init);

  return deepDive;

})(jQuery, app.breakpoints);
