app.header = (function($, Harvey, breakpoints){
  'use strict';

  var $header = $('.masthead');
  var $mobileMenu = $('.mobile-menu');
  var $footer = $('.site-footer');
  var $body = $('body');

  function bindMobileMenuToggle() {
    $('.js-mobile-menu-toggle').on('click', function(e){
      e.preventDefault();
      if ($mobileMenu.hasClass('is-open')) {
        closeMobileMenu();
      } else {
        openMobileMenu();
      }
    });
  }

  function openMobileMenu() {
    $mobileMenu.addClass('is-open').css('max-height', '100%');
    $body.addClass('has-open-mobile-menu');
  }

  function closeMobileMenu() {
    $mobileMenu.removeClass('is-open').css('max-height', '0%');
    $body.removeClass('has-open-mobile-menu');
  }

  function bindResponsive() {
    Harvey.attach('screen and (max-width: ' + breakpoints.mid + 'px)', {
      on: function() {
        $('.primary-nav', $header).appendTo($('.mobile-menu__primary-nav'));
        $('.search', $header).appendTo($('.mobile-menu__search'));
        $('.social', $footer).appendTo($('.mobile-menu__social'));
      },
      off: function() {
        $('.primary-nav', $mobileMenu).insertAfter($('.header-nav__logo', $header))
        .after();
        $('.search', $mobileMenu).appendTo($('.header-nav__search-wrap', $header));
        $('.social', $mobileMenu).appendTo($('.site-footer__social', $footer));

        closeMobileMenu();
      }
    });

    //Clone footer nav
    var footerNavClone = $('.site-footer__nav', $footer).clone();
    footerNavClone.find('.button').removeClass();
    footerNavClone.appendTo($('.mobile-menu__footer-nav'));
    //Clone anchor nav
    $('.anchor-nav', $footer).clone().appendTo($('.mobile-menu__anchor-nav'));
  }

  function init(){
    bindResponsive();
    bindMobileMenuToggle();
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, Harvey, app.breakpoints);
