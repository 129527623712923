'use strict';

angular.module('redlands')
  .component('rlProgramSearch', {
    bindings: {
      source: '@source'
    },
    template: ['<div class="feature-tabs feature-tabs--light is-scrollable" ng-show="vm.tabs.length > 1">',
      '<div class="feature-tabs__inner">',
      '<nav class="feature-tabs__items">',
      '<a ng-repeat="tab in vm.tabs" ng-click="vm.activateTab(tab)" class="feature-tabs__item" ng-class="{\'is-active\': vm.isActive(tab)}" search="search">{{tab.title}}</a>',
      '</nav></div></div>',
      '<div class="tab-content tab-content--dynamic">',


      '<div ng-if="vm.isSearchable()" class="form list-form ng-anim--fadeup"><form>',
        '<div class="form__group list-form__search">',
          '<div class="search search--dark">',
            '<div class="search__field-group">',
              '<input type="text" name="search" placeholder="Search for a program" class="form__control search__field" ng-model="vm.searchFilter.search" ng-change="vm.runFilter()" ng-model-options="{ updateOn: \'default blur\' }">',
              '<button type="submit" class="search__button form__control">Search</button>',
       '</div></div></div>',
       '<p class="grid-form__summary ng-anim--fadeup" ng-if="vm.isFiltered()">Your search returned {{vm.filteredCount()}} results. <a ng-click="vm.resetFilter()">Reset search</a>.</p>',
       '</form></div>',

      '<div class="grid-list__intro" ng-bind-html="vm.activeTab.overview | trust"></div>',

      '<ul class="grid-list__items columns columns--3 columns--equal-height">',
      '<rl-program-item ng-repeat="program in vm.filteredItems" ng-if="vm.programIsVisible($index)" rl-program-item="program" class="columns__item ng-anim--fadeup"></rl-program-item>',
      '<li ng-if="vm.items.length && vm.activeTab.url && vm.activeTab.buttonTitle" class="columns__item columns__item--flex-center ng-anim--fadeup"><a ng-href="{{vm.activeTab.url}}" class="button button--block">{{vm.activeTab.buttonTitle}}</a></li>',
      '</ul>',
      '<div ng-if="vm.hasMore() && !vm.isLoading" class="button__wrapper"><a ng-click="vm.loadMore()" class="button">Load More</a></div>',
      '<div ng-show="vm.isLoading"><div class="spinner"></div></div>',
      '</div>'
      ].join(''),
    controller: rlProgramSearch,
    controllerAs: 'vm',
    transclude: true
  });

rlProgramSearch.$inject = ['$element', 'TabsService', '$filter', '$timeout'];

function rlProgramSearch($element, TabsService, $filter, $timeout) {

  //Constants
  var itemsPerPage = 11;
  var pageIncrement = 8;

  //Bindables
  var vm = this;
  vm.activateTab = activateTab;
  vm.isActive = isActive;
  vm.resetFilter = resetFilter;
  vm.runFilter = runFilter;
  vm.hasMore = hasMore;
  vm.loadMore = loadMore;
  vm.isSearchable = isSearchable;
  vm.isFiltered = isFiltered;
  vm.activeTab;
  vm.searchFilter = {};
  vm.items = [];
  vm.filteredItems = [];
  vm.filteredResponse = false;
  vm.programIsVisible = programIsVisible;
  vm.visibleCount = itemsPerPage;
  vm.filteredCount = filteredCount;


  activate();

  /////////
  function activate() {
    TabsService.getSources(vm.source).success(function(data){
      vm.tabs = data;
      //Search for active, if none found activate first
      activateTab($filter('getActive')(vm.tabs) || vm.tabs[0]);

      //Initializing feature tabs after digest cycle (feature-tabs.js) so it's scrollable on smaller screens
      $timeout(function(){
        app.featureTabs.refreshElements();
        app.featureTabs.resizeLogic();
      });

    });
  }

  function programIsVisible(index) {
    return index < vm.visibleCount;
  }

  function activateTab(tab) {
    if (tab !== vm.activeTab) {
      vm.activeTab = tab;
      resetFilterFields();
      vm.items = [];
      vm.filteredItems = [];
      if (tab.source) {
        $element.find('.tab-content--static').removeClass('is-active');
        $element.find('.tab-content--dynamic').addClass('is-active');
        getItems();
      } else if (tab.tabSelector) {
        $element.find('.tab-content--dynamic').removeClass('is-active');
        $element.find(tab.tabSelector).addClass('is-active');
        vm.totalCount = 0;
      }
    }
  }

  function filteredCount() {
    return vm.filteredItems.length;
  }

  function isActive(tab) {
    return tab === vm.activeTab;
  }

  function hasMore() {
    return vm.visibleCount < vm.filteredItems.length;
  }

  function getItems() {
    vm.items = [];
    vm.isLoading = true;
    vm.filteredResponse = false;
    vm.totalCount = 0;
    TabsService.getItems(vm.activeTab).success(function(data){
      vm.items = vm.filteredItems = data.items;
      vm.totalCount = data.totalCount;
      vm.isLoading = false;
      vm.visibleCount = itemsPerPage;
    });
  }

  function loadMore() {
    if (vm.visibleCount < vm.items.length) {
      vm.visibleCount += pageIncrement;
    }
  }

  function resetFilterFields(){
    vm.searchFilter = {};
  }

  function resetFilter(){
    resetFilterFields();
    runFilter();
  }

  function runFilter(){
    vm.visibleCount = itemsPerPage;

    vm.filteredItems = vm.items.filter(function(item){
      if (!vm.searchFilter.search) {
        return true;
      } else {
        var contentIndex = (item.title + ' ' + item.description).toLowerCase();
        if (contentIndex.indexOf(vm.searchFilter.search.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      }
    });
  }

  function isSearchable() {
    return vm.totalCount > itemsPerPage;
  }

  function isFiltered() {
    return vm.searchFilter.search || false;
  }
}
