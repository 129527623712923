app.chatbox = (function($, breakpoints){
  'use strict';

  function init(){
    // if .chatbox exists
    if ($('.chatbox').length) {
      // if desktop width on initial load initiate timer
      if ($(window).width() > breakpoints.medium) {
        setTimeout(function(){
          // show chatbox (add class) after 10 seconds
          $('.chatbox').addClass('is-visible');
        }, 10000);
      }

      // Hide (remove class) when close button is clicked
      $('.js-chatbox-close').click(function(event) {
        event.preventDefault();
        $('.chatbox').removeClass('is-visible');
      });
    }
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, app.breakpoints);
