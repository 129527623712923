app.slider = (function($, Harvey, breakpoints) {
  'use strict';

  function init() {
    if (!$('.slider').length) {
      return;
    }

    $('.slider').each(createSlider);

    $(window).on('resize.redlands.sliders', function() {
      updateSliders();
    });

    //Prev / next arrows binding
    $('.js-slider-prev, .js-slider-next').on('click', function(e) {
      e.preventDefault();
      var $slider = $(this).closest('.slider');
      var owl = $slider.find('.owl-carousel').data('owlCarousel');
      if ($(this).hasClass('js-slider-prev')) {
        owl.prev();
      } else {
        owl.next();
      }
    });

    updateSliders();
  }


  function createSlider(index, slider) {
    var $slider = $(slider);
    var $owlCarousel = $slider.find('.owl-carousel');
    var $imgs = $slider.find('.slider__images img');
    var $ui = $slider.find('.slider__ui');
    var $slideCaption = $slider.find('.slider__slide-caption');
    var $sliderCaption = $slider.find('.slider__caption');
    var $sliderNav = $slider.find('.slider__nav');
    var $slideCaptionBody = $('<p class="slider__slide-caption-body js-slider-caption-body"></p>');
    var $testimonials = $slider.closest('.testimonials');
    //handle alternate slider...
    var $testimonialsAlt = $slider.closest('.testimonials-alt');




    //Detecting if slider has slide captions
    if ($('.slider__images img[data-caption]:not([data-caption=""])', $slider).length > 0) {

      //Letting slider know that it has slide captions
      $slider.addClass('has-slide-captions');

      //Preparing inner container
      $slideCaption.append($slideCaptionBody);

      //Detecting and fixing the size of the caption based on the longest caption
      $imgs.each(function() {
        var captionText = ($(this).data('caption')) ? $(this).data('caption') : '';
        var $newCaption = $('<span class="slider__slide-caption-item">' + captionText + '</span>');

        //Resetting width so layout can recalculate after we add a new caption
        $slideCaption.css('width', '');
        $slideCaptionBody.append($newCaption);

        //If the width of text is larger than previos captions, we measure and save it
        if ($slideCaption.data('width') < $slideCaption.outerWidth() || !$slideCaption.data('width')) {
          $slideCaption.data('width', $slideCaption.outerWidth());
          $slideCaption.css('width', $slideCaption.outerWidth());
        } else {
          $slideCaption.css('width', $slideCaption.data('width'));
        }
        $newCaption.addClass('is-ready');
      });


    }

    //Generating nav
    if ($imgs.length > 1) {
      $sliderNav.append('<a href="#" class="slider__arrow slider__arrow--prev js-slider-prev"></a><a href="#" class="slider__arrow slider__arrow--next js-slider-next"></a>');
    }

    //Hiding navigation if there's only one slide
    if ($('.slider__images img', $slider).length < 2) {
      $slider.addClass('has-single-slide');
    }

    //Initializing carousel
    var owl = $owlCarousel.owlCarousel({
      singleItem: true,
      pagination: false,
      itemsScaleUp: true,
      transitionStyle: 'fade',
      afterMove: function($owl) {
        var $slider = $owl.closest('.slider');
        var owl = this;

        if ($slider.hasClass('has-slide-captions')) {
          var $slideCaptionBody = $slider.find('.js-slider-caption-body');
          var $leaving = $('.slider__slide-caption-item', $slideCaptionBody).eq(owl.prevItem).removeClass('is-active').addClass('is-leaving');
          $('.slider__slide-caption-item', $slideCaptionBody).eq(owl.currentItem).addClass('is-active');
          setTimeout(function() {
            $leaving.removeClass('is-leaving');
          }, 300);
        }

        if ($testimonials.length > 0) {
          var testimonialsOwl  = $testimonials.find('.testimonials__items').data('owlCarousel');
          if (owl.currentItem !== testimonialsOwl.currentItem) {
            testimonialsOwl.goTo(owl.currentItem);
          }
        }

        if ($testimonialsAlt.length > 0) {
          var testimonialsAltOwl  = $testimonialsAlt.find('.testimonials-alt__items').data('owlCarousel');
          if (owl.currentItem !== testimonialsAltOwl.currentItem) {
            testimonialsAltOwl.goTo(owl.currentItem);
          }
        }

      }
    }).data('owlCarousel');

    bindResponsive($slider);
    $slider.addClass('is-initialized');
    $('.slider__slide-caption-item', $slideCaptionBody).eq(0).addClass('is-active');
  }

  function bindResponsive($slider) {
    var $sliderCaption = $('.slider__caption', $slider);
    Harvey.attach('screen and (max-width: ' + breakpoints.small + 'px)', {
      on: function() {
        $sliderCaption.fold().insertAfter($slider);
      },
      off: function() {
        $sliderCaption.fold('restore');
      }
    });
  }

  function updateSliders() {
    $('.slider').each(function(el) {
      var $slider = $(this);
      var $slideCaption = $(this).find('.slider__slide-caption');
      var $slideCaptionBody = $(this).find('.js-slider-caption-body');
      var $sliderCaption = $(this).find('.slider__caption');
      var $sliderNav = $(this).find('.slider__nav');


      //Setting max-width for caption
      var captionMaxWidth = $slider.width() - $sliderCaption.outerWidth() - $sliderNav.outerWidth() - 20;

      captionMaxWidth = (captionMaxWidth < 500) ? captionMaxWidth : 500;
      $slideCaption.css('max-width', captionMaxWidth);
    });
  }


  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, Harvey, app.breakpoints);