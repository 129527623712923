app.breakpoints = (function($){
  'use strict';

  return {
    full: 1400,
    large: 1280,
    mid: 960,
    medium: 767,
    small: 600,
    smaller: 420
  };

})(jQuery);
