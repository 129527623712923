/*
 *  Will check if there is a breadcrumb
 *   on the page and exit if not.  If there is a large breadcrumb, it will
 *   add binding to hide inner elements
 */
app.breadcrumb = (function($, breakpoints){
  'use strict';

  //Shared variables:
  var selector = '.breadcrumb',
      $breadcrumb,
      $breadcrumbItems;

  /*
   * Module init
   */
  function init(){
    //Exit early if no breadcrumb
    if(!$(selector).length) {
      return;
    }

    //Set shared vars
    $breadcrumb = $(selector);
    //TODO: remove this line when .current is removed from markup
    $breadcrumb.find('.current').remove();
    $breadcrumbItems = $('li', $breadcrumb);


    //Only bind hiding if we have any inner breadcrumb elements
    if($breadcrumbItems.length > 2) {
      //Do initial hide checker
      hideInnerElements();

      //Bind on resize.
      $(window).smartresize(hideInnerElements, 400);
    }
  }

  /*
   * On Resize, the breadcrumb should hide the elements in the middle
   *   so that the breadcrumb can stay visible on one line
   *   The breadcrumbs are setup to show the most important pages to
   *   the user, but not necessarily all of the pages. We will use JS
   *   to show at a minimum, the home page, section landing page,
   *   immediate parent page and the current page. The rest will be
   *   collapsed into the ellipsis as seen in the design. As the device
   *   width grows and provides additional room for more breadcrumb
   *   items, we will show more items on the page.
   */
  function hideInnerElements(){
    //Keeping track of the width of how many items we have in the breadcrumb
    var currentWidth,
        maxWidth,
        //Buffer amount for the ellipsis that may be added
        buffer = 35,
        $first = $breadcrumbItems.first(),
        $last = $breadcrumbItems.last(),
        $itemsToHide = $();

    //Reset the nav to the base state
    $breadcrumbItems.removeClass('is-hidden');
    $breadcrumb.removeClass('has-hidden');


    //Check max width of container
    maxWidth = $breadcrumb.width();

    //We always show the first and last item.  Add them to the current width
    currentWidth = $first.width();

    //Check each internal item to see if it will fit in the breadcrumb.
    //If it doesn't fit, we break the loop and hide the items
    //Get second to last: ignore first, because we already accounted for it
    $breadcrumbItems.slice(1).each(function(index, item){
      var itemWidth;
      itemWidth = $(item).width();
      //If we were to add this item, would it be too big?
      if((currentWidth + itemWidth + buffer) > maxWidth) {
        //We hide this item and the rest of the internal items
        $itemsToHide = $(item).nextAll().add(item);

        //No need to check anymore items
        return false;
      }
      //Item will fit.  Add it to the count
      else {
        currentWidth += $(item).width();
      }
    });

    //If we have items to hide
    if($itemsToHide.length){
      $itemsToHide.addClass('is-hidden');
      $breadcrumb.addClass('has-hidden');
    }
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

})(jQuery, app.breakpoints);
