app.contentAccordion = (function($){
'use strict';

  function init(){
    $('.content-accordion:not(.is-collapsed)').panelGroup({
      flexTabNav: false,
      tabNavAsList: false,
      selectors: {
          item: '.content-accordion__item',
          header: '.content-accordion__header',
          content: '.content-accordion__content'
        }
    });

    $('.content-accordion.is-collapsed').panelGroup({
      flexTabNav: false,
      tabNavAsList: false,
      firstAccordionOpen: false,
      selectors: {
          item: '.content-accordion__item',
          header: '.content-accordion__header',
          content: '.content-accordion__content'
        }
    });
  }
  $(document).on('ready', init);

  })(jQuery);
