app.navScroll = (function($){
  'use strict';

  var collapseScrollTolerance = 100;
  var direction = 1;
  var scrolled = 0;

  function bindScrollEvents() {
    $(window).on('scrolldelta', function(e){
      var newDirection = e.scrollTopDelta > 0 ? 1 : 0;
      if (newDirection !== direction) {
        scrolled = 0;
        direction = newDirection;
      } else {
        scrolled += Math.abs(e.scrollTopDelta);
      }

      //Hide on scroll and show collapsed on scroll up
      if (scrolled > collapseScrollTolerance || e.scrollTop < 90) {
        if (direction > 0 && e.scrollTop > 90) {
          $('.header-nav, .anchor-nav, body').addClass('is-hidden');
          $('.header-nav .search.is-open').removeClass('is-open').find('input').blur();
        } else {
          $('.header-nav, .anchor-nav body').removeClass('is-hidden');
        }
      }
    });
  }

  function init(){
    bindScrollEvents();
  }

  /* Document ready
  /* + + + + + + + + + + + + + + + + + + + + + + + + + + + */

  $(document).on('ready', init);

  $.event.special.scrolldelta = {
    delegateType: 'scroll',
    bindType: 'scroll',
    handle: function (event) {
      var handleObj = event.handleObj;
      var targetData = jQuery.data(event.target);
      var ret = null;
      var elem = event.target;
      var isDoc = elem === document;
      var oldTop = targetData.top || 0;
      var oldLeft = targetData.left || 0;
      targetData.top = isDoc ? elem.documentElement.scrollTop + elem.body.scrollTop : elem.scrollTop;
      targetData.left = isDoc ? elem.documentElement.scrollLeft + elem.body.scrollLeft : elem.scrollLeft;
      event.scrollTopDelta = targetData.top - oldTop;
      event.scrollTop = targetData.top;
      event.scrollLeftDelta = targetData.left - oldLeft;
      event.scrollLeft = targetData.left;
      event.type = handleObj.origType;
      ret = handleObj.handler.apply(this, arguments);
      event.type = handleObj.type;
      return ret;
    }
  };

})(jQuery);
